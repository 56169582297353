export const ITEM_STOCK_TYPE = [
  {
    id: 1,
    label: 'Update Stok',
  },
  {
    id: 2,
    label: 'Pengambilan',
  },
  {
    id: 3,
    label: 'Pembelian',
  },
]

export const ITEM_REQUEST_TYPE = [
  {
    id: 1,
    label: 'Pengambilan',
  },
  {
    id: 2,
    label: 'Pembelian',
  },
]

export const ITEM_REQUEST_STATUS = [
  {
    id: 0,
    label: 'Ditolak',
  },
  {
    id: 1,
    label: 'Pending',
  },
  {
    id: 2,
    label: 'Disetujui',
  },
  {
    id: 3,
    label: 'Barang Dikeluarkan',
  },
]

export const ITEM_PURCHASE_TYPE = [
  {
    id: 1,
    label: 'Pembelian',
  },
  {
    id: 2,
    label: 'Kas Kecil',
  },
]

export const ITEM_PURCHASE_STATUS = [
  {
    id: 0,
    label: 'Ditolak',
  },
  {
    id: 1,
    label: 'Pending',
  },
  {
    id: 2,
    label: 'Disetujui',
  },
  {
    id: 3,
    label: 'Barang Diterima',
  },
]

export const ITEM_UNITS = ['Batang', 'Buah', 'Bungkus', 'Butir', 'Dus', 'Gram', 'Gulung', 'Kaleng', 'Keping', 'Kilogram', 'Koli', 'Lembar', 'Liter', 'Meter', 'Meter Kubik', 'Meter Persegi', 'Pail', 'Pasang', 'Pcs', 'Rim', 'Roll', 'Sak', 'Set', 'Unit']
