type IconProps = React.SVGProps<SVGSVGElement>

export default function Edit({ className, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`fill-current ${className}`}
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11 20C11 19.4477 11.4477 19 12 19H21C21.5523 19 22 19.4477 22 20C22 20.5523 21.5523 21 21 21H12C11.4477 21 11 20.5523 11 20Z" fill="#79777F" className="fill-inherit" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18 3.87869C17.7026 3.87869 17.4174 3.99683 17.2071 4.20712L4.90295 16.5113L4.37436 18.6256L6.48874 18.097L18.7929 5.79291C18.897 5.68878 18.9796 5.56517 19.036 5.42912C19.0923 5.29308 19.1213 5.14727 19.1213 5.00001C19.1213 4.85276 19.0923 4.70695 19.036 4.5709C18.9796 4.43486 18.897 4.31124 18.7929 4.20712C18.6888 4.103 18.5651 4.0204 18.4291 3.96405C18.2931 3.9077 18.1472 3.87869 18 3.87869ZM15.7929 2.79291C16.3782 2.20754 17.1722 1.87869 18 1.87869C18.4099 1.87869 18.8158 1.95943 19.1945 2.11629C19.5732 2.27315 19.9173 2.50306 20.2071 2.79291C20.4969 3.08275 20.7269 3.42684 20.8837 3.80554C21.0406 4.18423 21.1213 4.59011 21.1213 5.00001C21.1213 5.40991 21.0406 5.81579 20.8837 6.19449C20.7269 6.57319 20.4969 6.91728 20.2071 7.20712L7.7071 19.7071C7.57894 19.8353 7.41836 19.9262 7.24253 19.9702L3.24253 20.9702C2.90175 21.0553 2.54126 20.9555 2.29288 20.7071C2.0445 20.4587 1.94465 20.0983 2.02985 19.7575L3.02985 15.7575C3.07381 15.5816 3.16473 15.4211 3.29288 15.2929L15.7929 2.79291Z" fill="#79777F" className="fill-inherit" />
    </svg>
  )
}
