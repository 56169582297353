export const ACCESS_CARD_TYPE = [
  {
    id: 1,
    label: 'Akses Masuk',
  },
  {
    id: 2,
    label: 'Akses Parkir',
  },
  {
    id: 3,
    label: 'Akses Masuk dan Parkir',
  },
]

export const VEHICLE_TYPE = [
  {
    id: 1,
    label: 'Mobil',
  },
  {
    id: 2,
    label: 'Motor',
  },
]

export const ACCESS_CARD_DURATION = [
  {
    id: 1,
    label: 'Tahun',
  },
  {
    id: 2,
    label: 'Bulan',
  },
]

export const ACCESS_CARD_STATUS = [
  {
    id: 1,
    label: 'Pending',
  },
  {
    id: 2,
    label: 'Menunggu Pembayaran',
  },
  {
    id: 3,
    label: 'Aktif',
  },
  {
    id: 4,
    label: 'Expired',
  },
]
